import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import ColorScheme from '@chayns-components/ColorScheme';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';

import handleSetActiveTapp from '../utils/handleSetActiveTapp';
import parseUserData from '../utils/parseUserData';
import TAPPIDS from '../constants/tapp-ids';
import ErrorBoundary from './error-boundary/ErrorBoundary';
import WaitCursor from './wait-cursor/WaitCursor';
import { renewAccessToken } from '../utils/TokenHelper';
import FloatingButton from './floating-button/FloatingButton';
import ImageWrapper from './image-wrapper/ImageWrapper';
import VideoWrapper from './video-wrapper/VideoWrapper';
import Tapp from './tapp/Tapp';
import { updateUserData } from '../utils/chayns-info';
import addOnActivateListener from '../utils/addOnActivateListener';
import SitesTapp from './tapp/tapp-content/sites-tapp/SitesTapp';
import VersionHint from './version-hint/VersionHint';
import BottomBar from './bottom-bar/BottomBar';
import usePrevious from '../utils/hooks/usePrevious';
import AppBanner from './app-banner/AppBanner';
import { getCookie } from '../utils/helper';
import clsx from 'clsx';
import setTitle from '../utils/setTitle';
import useResizer from '../utils/hooks/useResizer';
import useSmartClient from '../utils/hooks/useSmartClient';
import ContextMenu from './context-menu/ContextMenu';
import DialogView from './dialog-view/DialogView';
import useIntercom from '../utils/hooks/useIntercom';
import { dispatchEventListeners } from '../calls/json-chayns-call/calls/utils/jsonCallListeners';
import { getAppState } from '../redux-modules/app/selector';
import {
    setActiveSmartClientTapp,
    setActiveTapp, setFadeIn, setHotCardHeight,
    setIsChaynsIconInHeader,
    setIsClient,
    setIsMenuShown,
    setIsScannerShown,
    setIsTappScrolled,
    setLeftBarActive, setLoginOverlay, setNoDelay,
    setSelectedItem, setToolbarHeight,
} from '../redux-modules/app/actions';
import { getEnvState } from '../redux-modules/env/selector';
import { getTextStringState } from '../redux-modules/text-strings/selector';
import { getUserState } from '../redux-modules/user/selector';
import { setUser } from '../redux-modules/user/actions';
import { getTappsState } from '../redux-modules/tapps/selector';
import { getDialogState } from '../redux-modules/dialog/selector';
import { getGlobalLocationsState, getSitesState } from '../redux-modules/sites/selector';
import { fetchBadges, setTapps } from '../redux-modules/tapps/actions';
import {
    FloatingButtonType,
    setFloatingButton,
    setImageWrapper,
    setVideoWrapper,
    setWaitCursor,
} from '../redux-modules/components/actions';
import { getFloatingButtons } from '../redux-modules/components/selector';
import { handleLoadData } from '../redux-modules/smart-client/actions';
import { getSmartClientServerState } from '../redux-modules/smart-client/selector';
import { getIntercomState } from '../redux-modules/intercom/selector';
import { handleLoadSites } from '../redux-modules/sites/actions';
import { handleLoadNews } from '../redux-modules/news/actions';
import CalendarTapp from './tapp/tapp-content/calendar-tapp/CalendarTapp';
import DavidAppTapp from './tapp/tapp-content/david-app-tapp/DavidAppTapp';
import useTabCommunication from '../utils/hooks/useTabCommunication';
import useElectron from '../utils/electron/useElectron';
import setTappsHelper from '../utils/setTapps';
import handleLogin from '../utils/handleLogin';
import handleAppInit from '../utils/handleAppInit';
import useHistory from '../utils/hooks/useHistory';
import useTwoFactorPushConfirm from '../utils/hooks/useTwoFactorPushConfirm';
import HeaderBar from './header-bar/HeaderBar';
import { electron } from '../utils/electronUtils';
import useBottomBarHeight from '../utils/hooks/useBottomBarHeight';
import logger from '../constants/logger';
import LeftBarWrapper from './left-bar/LeftBarWrapper';
import { MOBILE_VIEW_BREAKPOINT } from '../constants/height';
import QrScannerMiddle from './left-bar/qr-scanner-middle/QrScannerMiddle';
import TopBar from './top-bar/TopBar';
import { getBorderColor } from '../utils/getBackgroundColor';
import LoginOverlay from './tapp/tapp-content/overlay/LoginOverlay';
import CalendarStore from './tapp/tapp-content/calendar-tapp/CalendarStore';
import SmartClientStore from './tapp/tapp-content/smartclient-tapp/SmartClientStore';
import IntercomWebsocketComponent from './util-components/IntercomWebsocketComponent';
import DialogV2 from './dialogv2/DialogV2';
import useAnimatedToolbar from '../utils/hooks/useAnimatedToolbar';
import { ColorSchemeProvider } from "@chayns-components/core";
import {isDEVELOPMENT, isPRODUCTION} from "../constants/environments";
import appCall from "../utils/appCall";
import useVrLogin from "../utils/hooks/useVrLogin";
import Infocenter from "./team-app-infocenter/Infocenter";
import LoginCompleted from "./tapp/tapp-content/login-completed/LoginCompleted";
import ChaynsLogoWhite from "./util-components/ChaynsLogoWhite";

const App: React.FC = () => {
    const dispatch = useDispatch();

    const app = useSelector(getAppState);
    const {
        activeTapp,
        isClient,
        isScannerShown,
        color,
        isChaynsReady,
        width,
        colorMode,
        isUserAuthenticated,
        activeDavidSiteId,
        isMenuShown,
        isSmartClientAvailable,
        isTappScrolled,
        hideOtherContent,
        isChaynsIconInHeader,
        selectedItem,
        params,
        leftBarActive,
        onlyTapp,
        onlySpringboard,
        fadeIn,
        noDelay
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isMobileBrowser,
        isMobile,
        isTablet,
        appVersion,
        isElectronClient,
        appWithoutHotCard,
        isIOS
    } = env;

    const user = useSelector(getUserState);

    const textStrings = useSelector(getTextStringState);

    const tapps = useSelector(getTappsState);

    const dialog = useSelector(getDialogState);

    const sites = useSelector(getSitesState);

    const globalLocations = useSelector(getGlobalLocationsState);

    const floatingButton = useSelector(getFloatingButtons);

    const server = useSelector(getSmartClientServerState);

    const intercom = useSelector(getIntercomState);

    const scannerCallbackRef = useRef(null);

    const prevSmartClientUrl = useRef(null);

    const prevWidth = usePrevious(width);

    const [isInitialRender, setIsInitialRender] = useState(true);
    const [tappIsHidden, setTappIsHidden] = useState(false);
    const fromPopState = useRef(false);
    const resizeTimeoutRef = useRef(null);

    useEffect(() => {
        if (activeTapp !== TAPPIDS.LANDING_PAGE && !isMyChaynsApp && !fadeIn && !onlyTapp) {
            dispatch(setNoDelay(true));
            dispatch(setFadeIn(false));

            setTimeout(() => {
                dispatch(setNoDelay(false));
            }, 500);

            setTimeout(() => {
                dispatch(setFadeIn(true));
            }, 1500);
        }
    }, [activeTapp])

    useResizer(dispatch);

    // Hook to load mails, add websocket
    useSmartClient({
        dispatch,
        server,
        tapps,
        activeDavidSiteId,
        isSmartClientAvailable,
        isClient,
        isUserAuthenticated,
        selectedItem,
        isChaynsReady,
        isMyChaynsApp,
        user,
    });

    // Hook to load intercom data, add websocket
    useIntercom({
        dispatch,
        intercom,
        isChaynsReady,
        selectedItem,
        isUserAuthenticated
    });

    useEffect(() => {
        dispatch(setIsClient(true));
    }, [dispatch]);

    // Hook to handle communication between multiple browser tabs
    useTabCommunication({
        isMyChaynsApp,
    });

    // Hide menu on width change
    useEffect(() => {
        if (isMenuShown && prevWidth !== width) {
            dispatch(setIsMenuShown(false));
        }
    }, [width, isMenuShown, prevWidth, dispatch]);

    // Add on activate listener
    useEffect(() => {
        const handleOnActivate = (isVisible: boolean) => {
            if (isVisible) {
                dispatch(handleLoadSites({ isMyChaynsApp }));
                if (typeof window.updateBadges === 'function') {
                    window.updateBadges();
                }
                if (!isMyChaynsApp) {
                    renewAccessToken();
                }
            }
        };
        return addOnActivateListener(handleOnActivate);
    }, [isMyChaynsApp, dispatch]);

    // Update website title
    useEffect(() => {
        if (tapps && !isMyChaynsApp) {
            setTitle(tapps, isElectronClient);
        }
    }, [tapps, isMyChaynsApp, activeTapp, isElectronClient, isClient]);

    useTwoFactorPushConfirm({ isChaynsReady, isMyChaynsApp });

    const handleAnimatedToolbarChange = useCallback(({ toolbarHeight, hotCardHeight }) => {
        dispatch(setHotCardHeight(hotCardHeight));
        dispatch(setToolbarHeight(toolbarHeight));
        document.body.style.setProperty('--app-bottom-height', `${toolbarHeight + hotCardHeight}px`);
    }, []);

    useVrLogin();

    useAnimatedToolbar({
        scrollContainer: global?.document?.querySelector('.chayns-de-content') || null,
        onChange: handleAnimatedToolbarChange,
        enabled: isMyChaynsApp && (activeTapp === TAPPIDS.SITES || activeTapp === TAPPIDS.CALENDAR) && appWithoutHotCard,
        addMinHeight: false
    })

    // Add electron hook to handle account switch
    useElectron({
        isElectronClient,
        updateUserData,
        user,
    });

    useElectron({
        user,
        isElectronClient,
        updateUserData,
    });

    useBottomBarHeight();

    // Handle activeTapp change and close dialog
    useEffect(() => {
        window.activeTapp = activeTapp;

        dispatch(setWaitCursor({}));
        const dialogElem = document.querySelector('.dialog-closezone') as HTMLElement;
        if (dialogElem) {
            dialogElem.click();
        }
    }, [activeTapp, dispatch]);

    const handleSetTapps = useCallback(async (hasUserLoggedIn, newTappId) => {
        setTappsHelper({
            hasUserLoggedIn,
            newTappId,
            textStrings,
            dispatch,
            isMobile,
            params,
        });
    }, [params, dispatch, textStrings, isMobile]);

    // Set window function to trigger update badges
    useEffect(() => {
        window.updateBadges = () => dispatch(fetchBadges({
            user,
            tapps,
        }));
    }, [dispatch, tapps, user]);

    useEffect(() => {
        dispatch(fetchBadges({
            user,
            tapps,
        }));
    }, []);

    useEffect(() => {
        window.loggedIn = async (hasUserLoggedIn: boolean, isGuardedAccountSelected: boolean, teamLogin: boolean) => {
            handleLogin({
                dispatch,
                params,
                hasUserLoggedIn,
                isGuardedAccountSelected,
                handleSetTapps,
                isMyChaynsApp,
                teamLogin,
            });
        };

        window.openLoginOverlay = (open) => {
            dispatch(setLoginOverlay(open));
        };
    }, [isMobile, isMyChaynsApp, isTablet, handleSetTapps, tapps, user, dispatch, params]);

    useEffect(() => {
        if (!isMyChaynsApp) {
            window.refreshTobitAt = async (isGuardian = false) => {
                await updateUserData(isGuardian);
                dispatch(setUser(parseUserData(isGuardian)));
                dispatchEventListeners(66, window.chaynsInfo.getGlobalData());
            };
        }
    }, [isMyChaynsApp, dispatch, user]);

    const setShowScanner = (value: boolean, callback: () => void) => {
        scannerCallbackRef.current = callback;
        dispatch(setIsScannerShown(value));
    };

    const hideMenus = () => {
        dispatch(setIsScannerShown(false));
        dispatch(setIsMenuShown(false));
    };

    const handleLoadTapp = useCallback((tappId) => {
        if (tappId === TAPPIDS.SITES && activeTapp === TAPPIDS.SITES && selectedItem) {
            dispatch(setSelectedItem(null));
        }

        if (isMenuShown) {
            dispatch(setIsMenuShown(false));
        }

        if (isScannerShown) {
            dispatch(setIsScannerShown(false));
        }

        if (tappId >= -2) {
            dispatch(setActiveTapp(tappId));
        }
    }, [activeTapp, dispatch, isMenuShown, isScannerShown, selectedItem]);

    // Add app init hook
    useEffect(() => {
        handleAppInit({
            isMyChaynsApp,
            params,
            dispatch,
            hideMenus,
            hideOtherContent,
            colorMode,
            appVersion,
            user,
            handleLoadTapp,
            setShowScanner,
            isIOS,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChaynsCall = useCallback((field, req) => {
        switch (field) {
            case 'floatingButton': {
                const newValue: Array<FloatingButtonType> = (() => {
                    const findConfig = floatingButton.findIndex((config) => config.id === req.id || config.id === activeTapp);
                    if (findConfig > -1) {
                        return floatingButton.map((config, i) => {
                            if (i === findConfig) {
                                return {
                                    ...req,
                                    id: req.id || activeTapp,
                                };
                            }
                            return config;
                        });
                    }
                    return [...floatingButton, {
                        ...req,
                        id: req.id || activeTapp,
                    }];
                })();
                dispatch(setFloatingButton(newValue));
                break;
            }
            case 'waitCursor':
                dispatch(setWaitCursor(req));
                break;
            case 'imageWrapper':
                dispatch(setImageWrapper(req));
                break;
            case 'videoWrapper':
                dispatch(setVideoWrapper(req));
                break;
            default:
                break;
        }
    }, [activeTapp, floatingButton, dispatch]);

    // Add chayns call listener
    useEffect(() => {
        window.handleChaynsCalls = handleChaynsCall;
    }, [activeTapp, floatingButton, dispatch, isScannerShown, isUserAuthenticated, handleChaynsCall]);

    // Load news, mails and threads on chaynsReady
    useEffect(() => {
        if (isUserAuthenticated && (sites?.values?.length > 0 || globalLocations) && isChaynsReady && !isMobile && !isTablet) {
            const useSites = sites?.values?.length > 0 ? sites.values : globalLocations;
            dispatch(handleLoadNews({
                sites: useSites,
            }));
        }

        if (isSmartClientAvailable && server?.length > 0 && isChaynsReady) {
            const lastDavidSiteId = getCookie('davidSiteId');
            const activeServer = server.find((s) => s.davidSiteId === lastDavidSiteId) || server[0];
            dispatch(handleLoadData(activeServer));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChaynsReady]);

    // Add scroll listener
    useEffect(() => {
        const handleScroll = throttle((e) => {
            const { scrollTop } = e.target;
            const didChaynsIconInHeaderUpdate = scrollTop > 90 !== isChaynsIconInHeader;
            if (isMobileBrowser && didChaynsIconInHeaderUpdate) {
                dispatch(setIsChaynsIconInHeader(scrollTop > 90));
            }
            const didIsTappScrolledUpdate = scrollTop > 0 !== isTappScrolled;
            if (didIsTappScrolledUpdate) {
                dispatch(setIsTappScrolled(scrollTop > 0));
            }
        }, 200);
        (document.querySelector('.chayns-de-content') || document.body).addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (document.querySelector('.chayns-de-content') || document.body).removeEventListener('scroll', handleScroll, { passive: true });
        };
    }, [isChaynsIconInHeader, isMobileBrowser, isTappScrolled, dispatch]);

    useEffect(() => {
        window.setActiveTapp = (tappId, tappParams) => {
            handleSetActiveTapp(tappId, activeTapp, dispatch, prevSmartClientUrl, fromPopState.current, isInitialRender);
            setIsInitialRender(false);
            if (tappParams) {
                const updatedTapps = tapps.map((tapp) => {
                    if (tapp.id === tappId) {
                        return {
                            ...tapp,
                            params: tappParams,
                        };
                    }
                    return tapp;
                });
                dispatch(setTapps(updatedTapps));
            }
        };
    }, [activeTapp, dispatch, isInitialRender, tapps]);

    const setPopStateRef = (value: boolean) => {
        fromPopState.current = value;
    };

    useEffect(() => {
        if (isMyChaynsApp || hideOtherContent) {
            document.documentElement.classList.add('chayns-app');
        }

        const token = (() => {
            if (typeof chaynsInfo !== 'undefined') return window.chaynsInfo.User?.TobitAccessToken;
            if (typeof chayns !== 'undefined') return chayns.env.user?.tobitAccessToken;
            return null;
        })();

        if (isMyChaynsApp && (!(token || user?.token) || !isUserAuthenticated)) {
            logger().error({
                message: 'no user token provided',
                data: {
                    isUserAuthenticated,
                    token: (token ?? '')?.length,
                    userToken: user?.token?.length,
                },
            });
        }

        const setHeight = throttle(() => {
            document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
            clearTimeout(resizeTimeoutRef.current);
            resizeTimeoutRef.current = setTimeout(() => {
                document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
            }, 100);
        }, 200);
        window.addEventListener('resize', setHeight, { passive: true });
        setHeight();

        return () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.removeEventListener('resize', setHeight, { passive: true });
        };
    }, []);

    useHistory({
        isInitialRender,
        activeTapp,
        dispatch,
        tapps,
        fromPopState,
        prevSmartClientUrl,
        setPopStateRef,
    });

    useEffect(() => {
        dispatch(setLeftBarActive(!isMyChaynsApp && width > MOBILE_VIEW_BREAKPOINT && !!user?.personId && !onlySpringboard));
    }, [width, isMyChaynsApp, user]);

    const activeSelectionName = useMemo(() => {
        const tapp = tapps.find((x) => x.id === activeTapp);
        return tapp?.showNames?.de ?? '';
    }, [activeTapp, tapps]);

    const intervalRef = useRef(null)

    useEffect(() => {
        if (isMyChaynsApp && !tappIsHidden) {
            appCall(0, { enabled: false });
            intervalRef.current = setInterval(() => {
                appCall(0, { enabled: false });
            }, 3000);
        } else if(tappIsHidden && intervalRef.current){
            clearInterval(intervalRef.current)
        }
    },  [tappIsHidden]);

    useEffect(() => {
        addOnActivateListener((hidden) => setTappIsHidden(!hidden))
    }, []);

    useEffect(() => {
        if(!user?.personId && isMyChaynsApp && !isPRODUCTION && isDEVELOPMENT){
            (async() => {
                const data = await appCall(18);
                document.cookie = `chayns_at_378=${data.AppUser.TobitAccessToken}`;
                await new Promise(r => setTimeout(r, 2000));
                location.reload();
            })();
        }
    }, []);

    useEffect(() => {
        window.handleSetSelection = ({
                                         davidSiteId,
                                         folderId,
                                         tappId,
                                         ...props
                                     }) => {
            if (folderId === 'calendar' || tappId === TAPPIDS.CALENDAR) {
                dispatch(setActiveTapp(TAPPIDS.CALENDAR));
            } else {
                if (location.href.includes('/folders/infocenter')) {
                   window.history.replaceState({ activeTapp: tappId }, '', location.href.replace('/infocenter', `/${folderId}`));
                }
                dispatch(setActiveTapp(tappId || TAPPIDS.SMART_CLIENT));
                dispatch(setActiveSmartClientTapp(folderId));
                if (typeof window.handleSetSelectionChaynsDe === 'function') {
                    window.handleSetSelectionChaynsDe({
                        davidSiteId,
                        folderId,
                        ...props,
                    });
                }
            }
        };
    }, [dispatch]);

    if (isMyChaynsApp || hideOtherContent) {
        return (
            <div
                className="color_scheme chayns-app"
            >
                <ColorSchemeProvider color={color} colorMode={colorMode === 'dark' ? 1 : 0} secondaryColor="#8e8e93">
                    <IntercomWebsocketComponent/>
                    <ErrorBoundary
                        section="floatingButton"
                    >
                        <FloatingButton/>
                    </ErrorBoundary>
                    <ErrorBoundary
                        section="contextMenu"
                    >
                        <ContextMenu/>
                    </ErrorBoundary>
                    <SmartClientStore/>
                    <ErrorBoundary
                        section="dialogV2"
                    >
                        <DialogV2/>
                    </ErrorBoundary>
                    <ErrorBoundary
                        section="appContent"
                    >
                        <DialogView
                            inProp={
                                (dialog && typeof dialog.dialogType === 'string')
                            }
                        />
                        <div className="calendar-dialog-view"/>
                        <div className="smart-client"><div className="david-dialog-view"/></div>

                        <ErrorBoundary section="calendarstore">
                            <CalendarStore/>
                        </ErrorBoundary>

                        <div className="content-wrapper isApp">
                            <div className="inner-content-wrapper">
                                <div className="ttt" style={{ minWidth: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                                    {
                                        isMyChaynsApp && activeTapp === TAPPIDS.SITES ? <ErrorBoundary
                                            section="headerBar"
                                        >
                                            <HeaderBar
                                                activeSelectionName={activeSelectionName}
                                            />
                                        </ErrorBoundary> : null
                                    }
                                    <div className="chayns-de-content" style={{maxHeight: '100vh',maxHeight: '100svh'}}>
                                        {activeTapp === TAPPIDS.CALENDAR && (
                                            <CalendarTapp
                                                isActive
                                            />
                                        )}

                                        {activeTapp === TAPPIDS.SMART_CLIENT && (
                                            <DavidAppTapp/>
                                        )}

                                        {activeTapp !== TAPPIDS.CALENDAR && activeTapp !== TAPPIDS.SMART_CLIENT && activeTapp !== TAPPIDS.INFOCENTER && (
                                            <SitesTapp/>
                                        )}
                                        {
                                            activeTapp === TAPPIDS.INFOCENTER ? <Infocenter/> : null
                                        }
                                        <ErrorBoundary
                                            section="versionHint"
                                        >
                                            <VersionHint/>
                                        </ErrorBoundary>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>
                </ColorSchemeProvider>

            </div>
        );
    }

    const wrapperBackgroundColor = colorMode === 'dark' ? '#121212' : '#fff';

    useEffect(() => {
        if (isElectronClient) {
            electron.updateTappTitle(activeSelectionName);
        }
    }, [activeSelectionName, isElectronClient]);

    const preventBorder = width < MOBILE_VIEW_BREAKPOINT || onlyTapp;

    if (activeTapp === TAPPIDS.LOGIN_COMPLETED) return <LoginCompleted/>;
    return (
        <>
            <ColorSchemeProvider color={color} colorMode={colorMode === 'dark' ? 1 : 0} secondaryColor="#8e8e93">
            <IntercomWebsocketComponent/>
            <h1
                className="seo-h1"
            >
                chayns
            </h1>
            <ColorScheme
                colorMode={colorMode === 'dark' ? 1 : 0}
                color={color}
                secondaryColor="#8e8e93"
                className={clsx("color_scheme main-color-scheme", { dark: colorMode === 'dark' })}
                cssVariables={{
                    '--chayns-color--headline': colorMode === 'dark' ? 'white' : '#222',
                    '--chayns-color--headline-1': colorMode === 'dark' ? 'white' : '#222',
                    '--chayns-color--headline-2': colorMode === 'dark' ? 'white' : '#222',
                    '--chayns-color--headline-3': colorMode === 'dark' ? 'white' : '#222',
                }}
            >
                <SmartClientStore/>
                <ErrorBoundary
                    section="waitCursor"
                >
                    <WaitCursor/>
                </ErrorBoundary>
                <ErrorBoundary
                    section="floatingButton"
                >
                    <FloatingButton/>
                </ErrorBoundary>
                <ErrorBoundary
                    section="imageWrapper"
                >
                    <ImageWrapper/>
                </ErrorBoundary>
                <ErrorBoundary
                    section="videoWrapper"
                >
                    <VideoWrapper/>
                </ErrorBoundary>
                <ErrorBoundary
                    section="contextMenu"
                >
                    <ContextMenu/>
                </ErrorBoundary>

                <ErrorBoundary section="calendarstore">
                    <CalendarStore/>
                </ErrorBoundary>

                <div className="calendar-dialog-view"/>
                <div className="smart-client"><div className="david-dialog-view"/></div>
                <DialogView
                    inProp={(dialog && typeof dialog.dialogType === 'string')}
                />
                <ErrorBoundary
                    section="dialogV2"
                >
                    <DialogV2/>
                </ErrorBoundary>

                <ChaynsLogoWhite/>

                <div style={{backgroundImage: 'url("tobit_software_logo.svg")' , position: "absolute", top: 10, right: 10, height: 80, width: 160, backgroundRepeat: 'no-repeat', opacity: fadeIn ? 0 : .6,filter: 'contrast(10)', transition: noDelay ? undefined : 'opacity .5s ease'}}/>

                <div
                    className={classNames('content-wrapper', {
                        mobile_browser: isMobileBrowser,
                    })}
                    style={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        '--mouseOverColor': colorMode === 'dark' ? '#343434' : 'var(--chayns-color--003)',
                    }}
                >
                    {leftBarActive && !onlyTapp ? <TopBar noDelay={noDelay} fadeIn={fadeIn || onlyTapp}/> : false}
                    <div className={clsx("inner-content-wrapper", { desktop: width > MOBILE_VIEW_BREAKPOINT })} style={{ opacity: (fadeIn) ? 1 : 0, transition: noDelay ? undefined : 'opacity 1s ease',maxHeight: (activeTapp === TAPPIDS.LANDING_PAGE || onlyTapp || isElectronClient) ? ((onlyTapp || isElectronClient) ? '100vh' : undefined) : width >= MOBILE_VIEW_BREAKPOINT ? 'calc(100vh - 64px)' : '100svh' }}>
                        {leftBarActive && !onlyTapp ? <LeftBarWrapper handleLoadTapp={handleLoadTapp}/> : false}
                        <div className="ttt" style={{ minWidth: 0, flexGrow: 1, display: 'flex', flexDirection: 'column', borderTop: (preventBorder || activeTapp === TAPPIDS.LANDING_PAGE) ? '' : getBorderColor(colorMode), borderLeft: (preventBorder || activeTapp === TAPPIDS.LANDING_PAGE) ? '' : getBorderColor(colorMode), borderTopLeftRadius: (preventBorder || activeTapp === TAPPIDS.LANDING_PAGE) ? '' : '8px' }}>
                            {!isMyChaynsApp && activeTapp !== TAPPIDS.LANDING_PAGE && !isElectronClient && !leftBarActive && !onlyTapp && !onlySpringboard && (
                                <ErrorBoundary
                                    section="headerBar"
                                >
                                    <HeaderBar
                                        activeSelectionName={activeSelectionName}
                                    />
                                </ErrorBoundary>
                            )}
                            {(onlyTapp || onlySpringboard) ? false : (
                                <ErrorBoundary
                                    section="appBanner"
                                >
                                    <AppBanner/>
                                </ErrorBoundary>
                            )}
                            <div className={clsx("chayns-de-content", {'show-scrollbar': activeTapp !== TAPPIDS.LANDING_PAGE})} style={{ background: wrapperBackgroundColor, borderTopLeftRadius: !preventBorder && width >= MOBILE_VIEW_BREAKPOINT ? 8 : undefined }}>
                                <div
                                    className={classNames('margin-wrapper', {
                                        'menu-active': (isScannerShown && !leftBarActive) || isMenuShown,
                                    })}
                                    onClick={() => {
                                        if (isScannerShown || isMenuShown) {
                                            hideMenus();
                                        }
                                    }}
                                >
                                    {leftBarActive && isScannerShown
                                        ? <div className="scanner-overlay-background"/> : false}
                                    <ErrorBoundary
                                        section="tapp"
                                    >
                                        <Tapp/>
                                    </ErrorBoundary>

                                    <LoginOverlay/>
                                </div>

                                <div className="bar-element"/>
                                <ErrorBoundary
                                    section="versionHint"
                                >
                                    <VersionHint/>
                                </ErrorBoundary>
                            </div>
                            {isUserAuthenticated && !onlyTapp && !onlySpringboard && (
                                <ErrorBoundary
                                    section="bottomBar"
                                >
                                    {leftBarActive ? (
                                        <QrScannerMiddle
                                            scannerCallback={scannerCallbackRef.current}
                                        />
                                    ) : (
                                        <BottomBar
                                            scannerCallback={scannerCallbackRef.current}
                                            handleLoadTapp={handleLoadTapp}
                                        />
                                    )}
                                </ErrorBoundary>
                            )}
                        </div>
                    </div>
                </div>
            </ColorScheme>
            </ColorSchemeProvider>
            <style jsx global>
                {`
                  .main-color-scheme.dark {
                      background-color: #121212;
                    .content-wrapper {
                      background-color: black;
                    }
                  }
                  * {
                    box-sizing: border-box;
                  }
                  
                  .seo-h1 {
                    display: none;
                    opacity: 0;
                    height: 0;
                    margin: 0;
                    padding: 0;
                  }

                  .chayns-color-mode--1 {
                    background-color: #121212;
                    .content-wrapper {
                      background-color: black;
                    }

                  }
                  .scrollbar {
                    &:not(:hover) {
                      &::-webkit-scrollbar-thumb {
                        background-color: transparent !important;
                      }
                    }
                  }

                  .bar-element {
                    height: 70px;
                    position: fixed;
                  }
                `}
            </style>
        </>
    );
};

App.displayName = 'App';

export default App;
