import React, {useState} from 'react';
import ChaynsLogo from "../util-components/ChaynsLogo";
import { useSelector } from "react-redux";
import { getAppState } from "../../redux-modules/app/selector";
import { getEnvState } from "../../redux-modules/env/selector";
import ManagedAccountsWrapper from "../header-bar/managed-accounts/ManagedAccountsWrapper";

const TopBar = ({ fadeIn, noDelay }) => {
    const app = useSelector(getAppState)
    const env = useSelector(getEnvState);
    const [managedAccountsOpen, setManagedAccountsOpen] = useState(false);
    const {
        colorMode,
        userAgent,
    } = app;

    const {
        isElectronClient
    } = env;

    if(isElectronClient) {
        const version = parseInt(userAgent.match(/electron-d3sc\/(\d+\.\d+.\d+)/i)?.[1]?.replaceAll('.', '') || 0);
        if(version < 260) return false;
    }

    return <>
        <div className="top-bar-wrapper" style={{ opacity: fadeIn ? 1 : 0, transition: noDelay ? undefined: 'opacity 1s ease' }}>
            <div className="chayns-logo-wrapper">
                <ChaynsLogo colorMode={'dark'} margin={8}/>
            </div>
            <ManagedAccountsWrapper isInDesktopView open={managedAccountsOpen}/>
        </div>

        <style jsx> {`
          .top-bar-wrapper {
            height: 64px;
            width: 100%;
            flex-shrink: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          
          .chayns-logo-wrapper {
              height: 100%;
              display: flex;
              align-items: center;
              margin-left: 21px;
              transform:scale(1.4);
          }
          
          .tobit-software-logo-wrapper {
            height: 100%;
            width: 153px;
            display: flex;
            align-items: center;
            margin-right: 20px;
            transform: scale(.85);
          }
          
        `}

        </style>
    </>
}

export default TopBar;
