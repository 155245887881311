import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dynamic from 'next/dynamic';
import classNames from 'classnames';

import SitesTapp from '../sites-tapp/SitesTapp';
import ErrorBoundary from '../../../error-boundary/ErrorBoundary';
import Messages from '../sites-tapp/messages/Messages';
import News from '../sites-tapp/news/News';
import IFrame from '../iframe/IFrame';
import TAPPIDS from '../../../../constants/tapp-ids';
import { HEADER_HEIGHT, MOBILE_VIEW_BREAKPOINT, BAR_HEIGHT } from '../../../../constants/height';
import { getJsonCallListener } from '../../../../calls/json-chayns-call/calls/utils/jsonCallListeners';
import { setUrlParams } from '../../../../utils/tapp/custom-tapp';
import { getAppState } from '../../../../redux-modules/app/selector';
import usePrevious from '../../../../utils/hooks/usePrevious';
import { User } from '../../../../types/user';
import getLanguage from '../../../../utils/getLanguage';
import ApiV5Tapp from '../ApiV5Tapp/ApiV5Tapp';
import SmartClientTapp from "../smartclient-tapp/SmartClientTapp";
import {Button} from "@chayns-components/core";
import {setLoginOverlay} from "../../../../redux-modules/app/actions";
import {getEnvState} from "../../../../redux-modules/env/selector";

const CalendarTapp = dynamic(() => import('../calendar-tapp/CalendarTapp'), {
    ssr: false,
});

type InnerTappContentTypes = {
    backgroundColor: string,
    bundleUrl?: string,
    isActive: boolean,
    isMobileBrowser: boolean,
    isMyChaynsApp: boolean,
    isTappAlreadyLoaded: boolean,
    url: string,
    overlayActive: boolean,
    postTobitAccessToken: boolean,
    tappId: number,
    tappIframeWrapperRef: React.LegacyRef<HTMLDivElement>,
    user: User,
    params,
    apiVersion?: number;
};

const InnerTappContent: React.FC<InnerTappContentTypes> = ({
                                                               backgroundColor,
                                                               bundleUrl,
                                                               isActive,
                                                               isMobileBrowser,
                                                               isMyChaynsApp,
                                                               isTappAlreadyLoaded,
                                                               url,
                                                               overlayActive,
                                                               postTobitAccessToken,
                                                               tappId,
                                                               tappIframeWrapperRef,
                                                               user,
                                                               params,
                                                               apiVersion,
                                                           }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        height,
        isSmartClientAvailable,
        width,
        colorMode,
        isUserAuthenticated,
        onlySpringboard
    } = app;

    const prevParams = usePrevious(params);

    const [reloadIFrame, setReloadIFrame] = useState(0);
    const tappUrl = useRef((() => {
        let temp = url && setUrlParams(url, tappId);
        temp += `&contentWidth=${width}&screenHeight=${height - HEADER_HEIGHT}&hideHeader=true&lang=${getLanguage()}`;
        return temp;
    })());

    useEffect(() => {
        if (tappId !== TAPPIDS.LANDING_PAGE && tappId !== TAPPIDS.SITES) {
            const srcFrame = document.getElementById(`TappIframe_${tappId > 0 ? tappId : tappId * -1}`);
            if (srcFrame) {
                const isAccessTokenChangeListenerAdded = getJsonCallListener(66, srcFrame);
                if (!isAccessTokenChangeListenerAdded) {
                    setReloadIFrame(reloadIFrame + 1);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.token]);

    useEffect(() => {
        let isEqual = false;
        if (Array.isArray(params) && Array.isArray(prevParams)) {
            isEqual = params.length === prevParams.length && params.every((val, i) => val === prevParams[i]);
        }

        if (params && !isEqual) {
            const paramArr = Array.isArray(params) ? params : [params];
            const paramJoined = paramArr.join('&');
            if (!tappUrl.current.includes(`&${paramJoined}`)) {
                tappUrl.current += `&${paramJoined}`;
            }
            setReloadIFrame((state) => state + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const iFrameStyles = useMemo(() => {
        if (!isActive) {
            return {
                zIndex: '0',
                pointerEvents: 'none',
            } as React.CSSProperties;
        }
        const tempStyles = {
            zIndex: '100',
            padding: '',
            pointerEvents: 'auto',
        } as React.CSSProperties;
        if (overlayActive) {
            tempStyles.pointerEvents = 'none';
        }
        return tempStyles;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, overlayActive, height, tappId, isTappAlreadyLoaded]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('showLogin') || (tappId === TAPPIDS.LANDING_PAGE && searchParams.has('t') && searchParams.has('teamLogin'))) {
            dispatch(setLoginOverlay({ tokenType: 'default'}))
        }
    }, []);

    if(tappId === TAPPIDS.LANDING_PAGE) {
        return (
            <div style={{ maxHeight: '100vh', maxHeight: '100svh'}}>
            <div className="bbbb" style={{ color: 'white',backgroundImage: 'url(chayns_background.png)', display: 'flex', justifyContent: 'center', fontSize: 24, flexDirection: 'column', textAlign: 'center', width: '-webkit-fill-available', height: '100vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                <img style={{margin: '0 auto',width: 242, objectFit: 'contain'}} src="https://tsimg.cloud/75508-15270/70e98c6ba7110da427af15569d4c2436d73fd528.png"/>

                <div style={{padding: '8px 12px'}}>

                <p><strong>chayns</strong> verbindet alles und alle. Meld Dich an, häng Dich dran!</p>
                <p>Kostenlos!</p>
                <br/>
                <div><Button onClick={() => {dispatch(setLoginOverlay({ tokenType: 'default'}))}}>Anmelden</Button></div>

                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                    <div style={{ fontSize: 17}}>

                        <a href="https://play.google.com/store/apps/details?id=com.Tobit.android.Slitte60021089891&hl=de">chayns für Android</a>&nbsp; - &nbsp;
                        <a href="https://apps.apple.com/de/app/chayns/id646297077"> chayns für iOS</a> &nbsp;- &nbsp;
                        <a onClick={() => {document.querySelector('.chayns-de-content').scrollBy({behavior: 'smooth', top: window.innerHeight+ 100})}}> Mehr über chayns</a>
                    </div>
                </div>
            </div>

                <div onClick={() => { document.querySelector('.chayns-de-content').scrollBy({behavior: 'smooth', top: window.innerHeight+100})}} style={{bottom: 0, display: 'flex', textAlign: 'center', alignItems: 'flex-end', left: 'calc(50% - 60px)', zIndex:1, position: 'absolute', cursor: 'pointer' }}>
                    <i style={{fontSize: 30, padding: '40px 50px', animation: 'arrow-down-bounce 2s infinite ease-in-out forwards', color: 'white'}} className="far fa-chevron-down"/>
                </div>


            </div>

                <div className="bbbb" style={{padding: '8px 12px', color: 'rgb(34, 34, 34)',backgroundColor: 'white', display: 'flex', justifyContent: 'center', fontSize: 17, flexDirection: 'column', textAlign: 'center', width: '-webkit-fill-available', height: '100vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
                    <img style={{margin: '10px auto',width: 242, objectFit: 'contain'}} src="https://tsimg.cloud/75508-15270/c683a1c57f1d7d570c7216080c3b96a5542c3fdf.png"/>

                    <div style={{maxWidth: 765, margin: '0 auto', textAlign: 'left'}}>
                        <p> chayns® verbindet alles und alle. Für Anbieter von Dienstleistungen, Waren oder Informationen ist es das perfekte System, da es alles auf jede nur erdenkliche Weise verfügbar macht. Und für die Menschen ist es perfekt, weil sie überall die gleiche Nutzer-Erfahrung erwartet.</p>
                        <br/>
                        <p>chayns® kümmert sich um Websites, Apps, Push-Nachrichten, Geld, Belege, Speicher, Wallets und alles, was es braucht...</p>
                    </div>

                    <br/>


                    <div style={{ display: 'flex',justifyContent: 'center', marginTop: 20}}>
                        <div><Button onClick={() => {window.open('https://chayns.site/?open=a2de5e18')}}>Mehr dazu</Button></div>
                        <div style={{marginLeft: 15}} className={"grey"}><Button onClick={() => {window.open('https://chayns.site/super-ka?open=faefc3a0')}}>Direkt loslegen</Button></div>
                    </div>
                </div>
                <style jsx global>
                    {
                        `
                          button {
                            color: rgb(0, 94, 184) !important;
                            background-color: white !important;
                            font-size: 19px;
                            font-weight: bold;
                          }


                           .bbbb .grey button {
                            background-color: rgb(243, 243, 243) !important;
                          }
                          

                          @keyframes arrow-down-bounce {
                            0% {
                              transform: translate3d(0, 0, 0);
                            }
                            50% {
                              transform: translate3d(0, 10px, 0);
                            }
                            100% {
                              transform: translate3d(0, 0, 0);
                            }
                          }
                        `
                    }

                </style>
            </div>



        )
    }

    if (tappId === TAPPIDS.SITES) {
        if (!isUserAuthenticated) {
            return null;
        }
        if (width < MOBILE_VIEW_BREAKPOINT) {
            return (
                <ErrorBoundary
                    section="sitesTapp"
                >
                    <SitesTapp/>
                </ErrorBoundary>
            );
        }

        const elemBackgroundColor = (() => {
            if (!isMyChaynsApp) {
                if (width >= MOBILE_VIEW_BREAKPOINT) {
                    return colorMode === 'light' ? 'white' : '#1E1E1E';
                }
                return colorMode === 'light' ? '#f7f7f7' : '#121212';
            }
            return 'unset';
        })();

        return (
            <div
                className={classNames('main-page-wrapper')}
                style={{
                    backgroundColor: width < MOBILE_VIEW_BREAKPOINT ? backgroundColor : null,
                }}
            >
                {onlySpringboard ? <SitesTapp/> : <><ErrorBoundary
                    section="messages"
                >
                    <Messages/>
                </ErrorBoundary>
                    <ErrorBoundary
                        section="sitesTapp"
                    >
                        <SitesTapp/>
                    </ErrorBoundary>
                    <ErrorBoundary
                        section="rightContentWrapper"
                    >
                                <div className="main-page-right">
                                    <ErrorBoundary
                                        section="News"
                                    >
                                        <News
                                            backgroundColor={elemBackgroundColor}
                                        />
                                    </ErrorBoundary>
                                </div>
                                <style jsx>
                                    {`
                                      .main-page-right {
                                        display: flex;
                                        flex-direction: column;
                                        width: 20%;
                                        min-width: 250px;
                                        max-width: 420px;
                                        z-index: 1;
                                        border-left: 1px solid ${colorMode === 'dark' ? 'rgba(255, 255, 255, .15)' : 'rgba(0, 0, 0, 0.15)'};
                                      }
                                    `}
                                </style>
                    </ErrorBoundary></>}
            </div>
        );
    }

    if (bundleUrl && tappId === TAPPIDS.SMART_CLIENT) {
        return (
            <ErrorBoundary
                section="smartclient"
            >
                <SmartClientTapp/>
            </ErrorBoundary>
        );
    }

    if (tappId === TAPPIDS.CALENDAR || (tappId === TAPPIDS.SMART_CLIENT)) {
        return (
            <ErrorBoundary
                section="calendarTapp"
            >
                <CalendarTapp
                    isActive={isActive}
                />
            </ErrorBoundary>
        );
    }

    if (!tappUrl.current) {
        return null;
    }

    if (apiVersion === 5) {
        return (
            <ErrorBoundary
                section="iFrame"
            >
                <ApiV5Tapp
                    key={`${tappId}_${reloadIFrame}`}
                    tappId={tappId}
                    tappIframeWrapperRef={tappIframeWrapperRef}
                    postTobitAccessToken={postTobitAccessToken}
                    tappUrl={url}
                    isActive={isActive}
                    overlayActive={overlayActive}
                    isTappAlreadyLoaded={isTappAlreadyLoaded}
                    isMobileBrowser={isMobileBrowser}
                    isMyChaynsApp={isMyChaynsApp}
                    iFrameStyles={iFrameStyles}
                    wrapperClassNames={classNames('tapp-iframe-wrapper', 'scrollbar', {
                        scrollable: isMobileBrowser && tappId !== TAPPIDS.LANDING_PAGE,
                        'not-active': !isActive,
                    })}
                />
            </ErrorBoundary>
        );
    }

    return (
        <ErrorBoundary
            section="iFrame"
        >
            <IFrame
                key={`${tappId}_${reloadIFrame}`}
                tappId={tappId}
                height={height - HEADER_HEIGHT - BAR_HEIGHT}
                tappIframeWrapperRef={tappIframeWrapperRef}
                postTobitAccessToken={postTobitAccessToken}
                tappUrl={tappUrl.current}
                isActive={isActive}
                overlayActive={overlayActive}
                isTappAlreadyLoaded={isTappAlreadyLoaded}
                isMobileBrowser={isMobileBrowser}
                isMyChaynsApp={isMyChaynsApp}
            />
        </ErrorBoundary>
    );
};

InnerTappContent.displayName = 'InnerTappContent';

InnerTappContent.defaultProps = {
    bundleUrl: null,
};

export default memo(InnerTappContent);
